import {HeroImageFragment, Maybe} from 'gatsby-graphql-types-generated'
import {graphql} from 'gatsby'

import Image from 'src/components/Image'

export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fluid(maxHeight: 300, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export interface HeroImageProps {
  url: string
  file?: Maybe<HeroImageFragment>
  alt: string
}

const HeroImage = ({url, alt, file}: HeroImageProps) => (
  <Image
    src={url}
    fluid={file?.childImageSharp?.fluid}
    maxHeight={300}
    alt={alt}
    cover
  />
)

export default HeroImage
