import {useState} from 'react'

import {Box} from 'src/components/Box'
import {Lede} from 'src/components/text'
import Button from 'src/components/Button'
import {Action} from 'src/components/Action'
import * as icons from 'src/components/icons'

import {GuideSection} from './GuideSection'

// eslint-disable-next-line no-alert
const alertOnClick = () => alert('You clicked me!')

const ButtonSection = () => {
  const [busy, setIsBusy] = useState(false)

  const busyOnClick = () => {
    setIsBusy(true)
    setTimeout(() => setIsBusy(false), 3000)
  }

  return (
    <GuideSection title="Buttons">
      <Box>
        <Button onClick={alertOnClick}>Primary</Button>
        <Button onClick={alertOnClick} variant="secondary">
          Secondary
        </Button>
      </Box>
      <Box>
        <Button type="submit" disabled>
          Disabled
        </Button>
        <Button variant="secondary" type="submit" disabled>
          Disabled
        </Button>
      </Box>

      <Box>
        <Button busy={busy} onClick={busyOnClick}>
          Async Button
        </Button>

        <Button busy={busy} onClick={busyOnClick} variant="secondary">
          Async Button
        </Button>
      </Box>

      <Action onClick={alertOnClick}>Action (clear button)</Action>

      <Box>
        <Lede>Action with a icon</Lede>
        <Action onClick={alertOnClick} type="button">
          <icons.PencilIcon
            hoverColor="grey3"
            color="grey2"
            width="1rem"
            height="1rem"
          />
        </Action>
      </Box>
    </GuideSection>
  )
}

export default ButtonSection
