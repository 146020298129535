import {
  forwardRef,
  DetailedHTMLProps,
  LabelHTMLAttributes,
  TextareaHTMLAttributes,
} from 'react'
import {useField, UseFieldConfig} from 'react-final-form'
import styled from '@emotion/styled'

import {identity} from 'src/form/parse'
import {PositionContainer} from 'src/components/Box'
import {theme, animation} from 'src/styles'

import {Label, ErrorMessage, TextArea} from './input'
import {hasFieldError} from './TextField'

export interface Props extends UseFieldConfig<string> {
  label: string
  name: string
  id?: string
  type?: 'text' | 'email' | 'password'
  autoFocus?: boolean
  readOnly?: boolean
  cols?: number
  rows?: number
}

type TextAreaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>

export interface BasicTextFieldProps {
  inputProps: TextAreaProps
  labelProps: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
  label: string
}

const Container = styled.div<{hasValue: boolean}>(
  {
    position: 'relative',
    paddingTop: theme.space[3],
    'textarea + label': {
      position: 'absolute',
      top: 0,
      left: theme.space[1],
    },
    'textarea:focus:not(:read-only) + label': {
      transition: animation.fadeIn('all'),
      transform: 'translateY(0)',
      fontSize: theme.fontSizes.extraSmall,
      left: 0,
    },
  },
  ({hasValue}) => ({
    'textarea + label': {
      transition: hasValue ? animation.fadeIn('all') : animation.fadeOut('all'),
      transform: hasValue ? 'translateY(0)' : 'translateY(100%)',
      fontSize: hasValue ? theme.fontSizes.extraSmall : theme.fontSizes.body,
      left: hasValue ? 0 : theme.space[1],
    },
  })
)

export const BasicTextAreaField = forwardRef<
  HTMLTextAreaElement,
  BasicTextFieldProps
>(({label, inputProps, labelProps}, ref) => (
  <Container hasValue={Boolean(inputProps.value)}>
    <TextArea {...inputProps} ref={ref} />
    <Label {...labelProps}>{label}</Label>
  </Container>
))

const TextAreaField = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      label,
      name,
      id,
      parse = identity,
      autoFocus = false,
      readOnly = false,
      ...config
    },
    ref
  ) => {
    const {input, meta} = useField<string, HTMLTextAreaElement>(name, {
      parse,
      ...config,
    })

    const showError = !readOnly && hasFieldError(meta)
    const inputId = id || name
    return (
      <PositionContainer pb="3" position="relative">
        <BasicTextAreaField
          ref={ref}
          label={label}
          inputProps={{
            ...input,
            readOnly,
            autoFocus,
            id: inputId,
          }}
          labelProps={{htmlFor: inputId}}
        />
        {showError && (
          <PositionContainer position="absolute" bottom="0" left="0">
            <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>
          </PositionContainer>
        )}
      </PositionContainer>
    )
  }
)

export default TextAreaField
