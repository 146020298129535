import {useMap} from 'react-use'

import Layout from 'src/components/Layout'
import {Flex, Grid, PositionContainer} from 'src/components/Box'
import {SmallBody} from 'src/components/text'
import ButtonLink from 'src/components/ButtonLink'
import {ActionLink} from 'src/components/Action'
import RelatedContent from 'src/components/RelatedContent'
import {
  Divider,
  SquiggleDivider,
  PurpleSquiggle,
  GreenSquiggle,
} from 'src/components/dividers'
import {FlairProvider} from 'src/context/FlairContext'
import * as styles from 'src/styles'
import JobSearchCta from 'src/components/JobSearchCta'

import TypographySection from './TypographySection'
import FormSection from './FormSection'
import ModalSection from './ModalSection'
import FlairSection from './FlairSection'
import ListSection from './ListSection'
import IconSection from './IconSection'
import BreakpointSection from './BreakpointSection'
import CardSection from './CardSection'
import ToastSection from './ToastSection'
import ColorSection from './ColorSection'
import SpacingSection from './SpacingSection'
import JourneySection from './JourneySection'
import ButtonSection from './ButtonSection'
import LoadingSection from './LoadingSection'
import {GuideSection, SectionContext} from './GuideSection'

const StyleguideNav: React.FunctionComponent<{
  sectionMap: Record<string, string>
}> = ({sectionMap}) => (
  <nav css={{gridArea: 'nav'}}>
    <PositionContainer
      as="nav"
      position="sticky"
      top={styles.constants.DESKTOP_HEADER_HEIGHT}
      gridArea="nav"
      zIndex={styles.zIndexes.header}
    >
      <Flex flexDirection="column">
        {Object.entries(sectionMap).map(([k, v]) => (
          <a key={k} href={`#${k}`}>
            <SmallBody>{v}</SmallBody>
          </a>
        ))}
      </Flex>
    </PositionContainer>
  </nav>
)

const Styleguide = () => {
  const [sectionMap, {set: registerId, remove: unregisterId}] = useMap<
    Record<string, string>
  >({})

  return (
    <SectionContext.Provider value={{registerId, unregisterId}}>
      <FlairProvider seed={Math.random()}>
        <Layout>
          <Grid
            gridTemplateAreas="'nav content'"
            gridTemplateColumns="200px 1fr"
          >
            <StyleguideNav sectionMap={sectionMap} />
            <Flex flexDirection="column" gridArea="content">
              <TypographySection />

              <BreakpointSection />

              <SpacingSection />

              <ColorSection />

              <LoadingSection />

              <FormSection />

              <ButtonSection />

              <GuideSection title="Links">
                <a href="http://example.com">Plain Link</a>
                <a href="/styleguide">Plain Link Styleguide</a>
                <ActionLink to="/sign-up">Action Link Sign Up</ActionLink>
                <ActionLink to="/styleguide">Action Link Styleguide</ActionLink>
                <ButtonLink to="/sign-up">Button Link Sign up →</ButtonLink>
                <ButtonLink variant="secondary" to="/">
                  Button Link (secondary)
                </ButtonLink>
              </GuideSection>

              <IconSection />
              <FlairSection />

              <ListSection />

              <ModalSection />
              <CardSection />
              <ToastSection />

              <GuideSection title="Dividers">
                <Divider />
                <Divider thin />
                <SquiggleDivider />
                <PurpleSquiggle />
                <GreenSquiggle />
              </GuideSection>

              <GuideSection title="Related Content">
                <RelatedContent
                  articles={[1, 2, 3, 4].map((id) => ({
                    id: `${id}`,
                    title: 'Lorem ipsum dolor sit amet.',
                    summary: {
                      summary:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sodales, magna molestie molestie lobortis, ante est porttitor sem, nec condimentum odio nibh in purus. Aliquam non tortor lacinia, rhoncus dui in, congue velit. Duis porta volutpat odio tempor tempor.',
                    },
                    permalink: '/styleguide',
                  }))}
                />
              </GuideSection>

              <GuideSection title="Job Search CTA">
                <JobSearchCta
                  heading="Lorem ipsum dolor sit amet"
                  text="Aenean sodales, magna molestie molestie lobortis, ante est porttitor sem, nec condimentum odio nibh in purus."
                  linkLabel="Lorem!"
                  linkUrl="/styleguide"
                />
              </GuideSection>

              <JourneySection />
            </Flex>
          </Grid>
        </Layout>
      </FlairProvider>
    </SectionContext.Provider>
  )
}

export default Styleguide
