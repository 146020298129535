import {Link, graphql} from 'gatsby'
import {RelatedArticleFragment} from 'gatsby-graphql-types-generated'

import {Box, Flex, Grid} from 'src/components/Box'
import {Header4, SmallBody, Header1} from 'src/components/text'
import Card from 'src/components/Card'
import {RelatedSquiggle} from 'src/components/dividers'

export const query = graphql`
  fragment RelatedArticle on ContentfulArticleTile {
    id
    permalink
    title
    summary {
      summary
    }
  }
`

const ArticleCard = ({
  title,
  text,
  url,
}: {
  title: string
  text: string
  url: string
}) => (
  <Link to={url} css={{textDecoration: 'none'}}>
    <Card hoverable>
      <Header4>{title}</Header4>
      <SmallBody color="grey5" mt="2" lines={3}>
        {text}
      </SmallBody>
    </Card>
  </Link>
)

interface Props {
  articles: RelatedArticleFragment[]
}

const RelatedContent = ({articles}: Props) => (
  <Box>
    <Flex flexDirection="column" alignItems="center" mb="5">
      <Header1 as="h2" mb="2">
        Related
      </Header1>
      <RelatedSquiggle />
    </Flex>

    <Grid gridGap={{_: 2, md: 4}} gridTemplateColumns={{md: 'repeat(2, 1fr)'}}>
      {articles.map(({id, permalink, title, summary}) => (
        <ArticleCard
          key={id}
          title={title}
          text={summary?.summary}
          url={permalink}
        />
      ))}
    </Grid>
  </Box>
)
export default RelatedContent
