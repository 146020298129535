import {useState} from 'react'

import JobCard from 'src/components/JobCard'
import Stack from 'src/components/Stack'
import Card from 'src/components/Card'
import {Box, Flex} from 'src/components/Box'
import {Body, Header3} from 'src/components/text'
import SimpleCard from 'src/components/SimpleCard'
import ResourcePageCard from 'src/components/Journey/ResourcePageCard'

import {GuideSection} from './GuideSection'

const buildJob = () =>
  ({
    jobTitle: `Some Position at a company`,
    company: 'The Company',
    formattedLocationFull: 'Seattle, WA 98112',
    url: '#',
    onmousedown: '',
    id: 'abd123',
    snippet:
      'Since 1989, we have developed technology environments, applications, and tools by providing experienced teams to implement, enhance, and maintain our clients’…',
  } as const)

const StackDemo = () => {
  const [jobs, setJobs] = useState([
    buildJob(),
    buildJob(),
    buildJob(),
    buildJob(),
    buildJob(),
    buildJob(),
  ])
  const fetchMore = () => {
    setJobs((jobs) => [...jobs, buildJob(), buildJob(), buildJob(), buildJob()])
  }

  return (
    <Flex flexDirection="column">
      <Body>Swipe left/right</Body>
      <Stack
        containerHeight="400px"
        fetchMore={jobs.length < 20 ? fetchMore : undefined}
      >
        {jobs.map((j, i) => (
          <JobCard key={i} job={j} />
        ))}
        <div>Out of Cards</div>
      </Stack>
    </Flex>
  )
}

const CardSection = () => {
  return (
    <GuideSection title="Cards">
      <Header3>Stack</Header3>
      <StackDemo />

      <JobCard
        job={{
          jobTitle:
            'Some Position at a company that has a really long title so it will probably need to wrap and hopefully clamp at a few lines',
          company: 'The Company',
          formattedLocationFull: 'Seattle, WA 98112',
          url: '#',
          onmousedown: '',
          id: 'abd123',
          snippet:
            'Since 1989, we have developed technology environments, applications, and tools by providing experienced teams to implement, enhance, and maintain our clients’…',
        }}
      />

      <Header3>Card</Header3>
      <Card hoverable>{'I am <Card>'}</Card>
      <Card backgroundImage="https://picsum.photos/600/300">
        <Box bg="white" mx="100px">
          Card with background image
        </Box>
      </Card>
      <SimpleCard p={3}>Simple thin line, no shadow</SimpleCard>
      <Header3>Resource Page Card</Header3>
      <ResourcePageCard
        resourcePage={{
          __typename: 'ContentfulResourcePage',
          id: '1',
          title: 'Text Us',
          blurb: {blurb: 'Send us a text and we will answer'},
          permalink: '/text-us',
        }}
      />
    </GuideSection>
  )
}

export default CardSection
