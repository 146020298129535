import Toast from 'src/components/Toast'
import Button from 'src/components/Button'
import {useConfettiCannon} from 'src/context/ConfettiContext'

import {GuideSection} from './GuideSection'
import {simpleToast, toastWithHeading} from '../ToastTemplates'

const ToastSection = () => {
  const {fireConfetti} = useConfettiCannon()

  return (
    <GuideSection title="Toasts">
      <Toast body={simpleToast('I am <Toast>')} clear={() => {}} />
      <Toast
        body={toastWithHeading({
          heading: 'Fancy toast',
          emoji: {
            emoji: '🥑',
            label: 'avocado',
          },
          text: 'Butter side up.',
        })}
        clear={() => {}}
      />

      <Button
        onClick={() => {
          fireConfetti()
        }}
      >
        Fire Confetti
      </Button>
    </GuideSection>
  )
}

export default ToastSection
