import {useTheme} from '@emotion/react'

import {Box, Flex, Grid} from 'src/components/Box'
import {Lede, SmallBody} from 'src/components/text'
import * as styles from 'src/styles'

import {GuideSection} from './GuideSection'
import ColorWall from '../ColorWall'

const ColorSection = () => {
  const theme = useTheme()
  return (
    <GuideSection title="Colors">
      <Grid
        mt="3"
        gridGap="1em"
        gridTemplateColumns="repeat(auto-fill, minmax(6rem, 1fr))"
      >
        {Object.entries(theme.colors).map(([name, value]) => (
          <Flex
            key={name}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box mb="2" width="6rem" height="6rem" background={value} />
            <Lede>{name}</Lede>
          </Flex>
        ))}
      </Grid>

      <Grid
        mt="3"
        gridGap="1em"
        gridTemplateColumns="repeat(auto-fill, minmax(6rem, 1fr))"
      >
        {Object.entries(styles.gradients).map(([name, value]) => (
          <Flex
            key={name}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box mb="2" width="6rem" height="6rem" background={value} />
            <SmallBody>{name}</SmallBody>
          </Flex>
        ))}
      </Grid>

      <Box position="relative" p={3}>
        <ColorWall
          css={{background: styles.gradients.mintLakeBlue, opacity: 0.4}}
        />
        {`<ColorWall>`} expects parent to be position: relative
      </Box>
    </GuideSection>
  )
}

export default ColorSection
