import {useState, ReactNode} from 'react'

const StateContainer = <T extends any>({
  children,
  initialValue,
}: {
  children: (props: {state: T; setState: (s: T) => void}) => ReactNode
  initialValue: T
}) => {
  const [state, setState] = useState(initialValue)
  return <>{children({state, setState})}</>
}

export default StateContainer
