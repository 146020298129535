import shouldForwardProp from '@styled-system/should-forward-prop'
import styled from '@emotion/styled'

import {BoxProps, boxStyles} from 'src/components/Box'
import {simpleBorder} from 'src/styles/borders'

const SimpleCard = styled('div', {shouldForwardProp})<BoxProps>(
  boxStyles,
  simpleBorder(2),
  {
    borderRadius: '10px',
  }
)

export default SimpleCard
