import {Ref as ReactRef} from 'react'
import {Config} from 'final-form'

import InlineField from 'src/components/form/InlineField'
import {PositionContainer} from 'src/components/Box'
import {PencilIcon, AcceptIcon} from 'src/components/icons'
import {Action} from 'src/components/Action'
import Loading from 'src/components/Loading'
import {shadow} from 'src/styles'

interface Props {
  name: string
  label: string
  initialValue: string
  onSubmit: (value: string) => Promise<void | string> | void | string
  children: (props: {
    inputRef: ReactRef<HTMLInputElement>
    label: string
    name: string
    editable: boolean
    submitting: boolean
  }) => React.ReactNode
}

const InlineTextField = ({
  onSubmit,
  name,
  label,
  initialValue,
  children,
}: Props) => {
  const handleOnSubmit: Config<Record<string, string>>['onSubmit'] = async (
    values
  ) => {
    const result = await onSubmit(values[name])
    if (result) {
      return {
        [name]: result,
      }
    }

    return
  }
  return (
    <InlineField
      onSubmit={handleOnSubmit}
      initialValues={{[name]: initialValue}}
    >
      {({inputRef, editable, setEditable, submitting}) => (
        <PositionContainer position="relative">
          {children({
            inputRef,
            name,
            editable,
            submitting,
            label,
          })}

          <PositionContainer position="absolute" right="0" verticalCenter>
            {!editable && (
              <Action
                onClick={() => setEditable(!editable)}
                type="button"
                data-cy={`edit-${name}`}
                aria-label="Edit"
              >
                <PencilIcon
                  hoverColor="grey3"
                  color="grey2"
                  width="1rem"
                  height="1rem"
                  p={2}
                  mr={-2}
                />
              </Action>
            )}
            {editable &&
              (submitting ? (
                <Loading size="small" />
              ) : (
                <Action
                  type="submit"
                  data-cy={`confirm-${name}`}
                  aria-label="Save"
                >
                  <AcceptIcon
                    css={{
                      '&:hover': shadow.containerShadow,
                    }}
                    height="2rem"
                    width="2rem"
                  />
                </Action>
              ))}
          </PositionContainer>
        </PositionContainer>
      )}
    </InlineField>
  )
}

export default InlineTextField
