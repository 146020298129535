import {useTheme} from '@emotion/react'

import {Header1, Body} from 'src/components/text'
import {Flex, Box} from 'src/components/Box'
import {Maybe} from 'src/graphql-generated'
import HeroImage, {HeroImageProps} from 'src/components/HeroImage'

interface Props {
  heroUrl?: Maybe<string>
  title: string
  heroFile?: HeroImageProps['file']
  description?: Maybe<string>
}

const JourneyStart = ({heroUrl, heroFile, title, description}: Props) => {
  const theme = useTheme()
  return (
    <Flex flexDirection="column">
      {heroUrl && (
        <Box mx={{_: -3, lg: 0}}>
          <HeroImage url={heroUrl} file={heroFile} alt={title} />
        </Box>
      )}
      <Box maxWidth={{md: '675px'}} alignSelf={{md: 'center'}}>
        <Header1
          my="4"
          mt={{lg: 6}}
          textAlign={{md: 'center'}}
          fontSize={{lg: theme.fontSizes.headerXl}}
        >
          {title}
        </Header1>
        {description && <Body>{description}</Body>}
      </Box>
    </Flex>
  )
}

export default JourneyStart
