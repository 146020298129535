import Modal from 'src/components/Modal'
import {Box, Flex} from 'src/components/Box'
import Button from 'src/components/Button'
import AccountPromptModal from 'src/components/AccountPromptModal'

import {GuideSection} from './GuideSection'
import StateContainer from './StateContainer'

const ModalSection = () => (
  <>
    <GuideSection title="Modal">
      <StateContainer initialValue={false}>
        {({state, setState}) => (
          <>
            <Button onClick={() => setState(true)}>Show Modal</Button>
            <Modal isOpen={state} onRequestClose={() => setState(false)}>
              <Flex
                width="100vw"
                p="3"
                flexDirection="column"
                alignItems="center"
              >
                Max Width Content
                <Box mt="4">
                  <Button onClick={() => setState(false)}>Close</Button>
                </Box>
              </Flex>
            </Modal>
          </>
        )}
      </StateContainer>
    </GuideSection>
    <GuideSection title="AccountPromptModal">
      <StateContainer initialValue={false}>
        {({state, setState}) => (
          <>
            <Button onClick={() => setState(true)}>Show Modal</Button>
            <AccountPromptModal
              isOpen={state}
              onRequestClose={() => setState(false)}
              prompt="Create an account to see really good content"
            />
          </>
        )}
      </StateContainer>
    </GuideSection>
  </>
)

export default ModalSection
