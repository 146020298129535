import {BUNDLE_SETS} from 'src/lib/flair'
import BackgroundImage from 'src/components/BackgroundImage'
import {Grid} from 'src/components/Box'
import {Lede, Header3} from 'src/components/text'

import {GuideSection} from './GuideSection'

const FlairSection = () => (
  <GuideSection title="Flair">
    <p>There are a bunch of sets, here are some examples</p>
    {Object.entries(BUNDLE_SETS)
      .slice(0, 2)
      .map(([name, bundle]) => (
        <Grid gridGap={3} key={name}>
          <Header3>{name}</Header3>
          <Lede>Box Flair</Lede>
          <Grid
            mt="3"
            gridGap="1em"
            gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
          >
            {bundle.flair.map((flairUrl) => (
              <BackgroundImage
                key={flairUrl}
                width={200}
                height={200}
                url={flairUrl}
              />
            ))}
          </Grid>
          <Lede>Related Flair</Lede>
          <BackgroundImage width={250} height={40} url={bundle.related} />
        </Grid>
      ))}
  </GuideSection>
)

export default FlairSection
