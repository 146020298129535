import {useTheme} from '@emotion/react'

import {Box, Flex, Grid} from 'src/components/Box'
import {Lede} from 'src/components/text'

import {GuideSection} from './GuideSection'

const SpacingSection = () => {
  const theme = useTheme()
  return (
    <GuideSection title="Spacing">
      <Lede>Width x Height of spacing unit</Lede>

      <Grid
        gridGap="1em"
        gridTemplateColumns="repeat(auto-fill, minmax(6rem, 1fr))"
      >
        {theme.space.map((val, index) => (
          <Flex flexDirection="column" alignItems="center" key={val}>
            <Box mb="3" height={val} width={val} background="purple" />
            <Lede>
              {index} - {val}
            </Lede>
          </Flex>
        ))}
      </Grid>
    </GuideSection>
  )
}

export default SpacingSection
