import Article from 'src/components/Journey/Article'
import JourneyStart from 'src/components/Journey/JourneyStart'
import MilestoneCard from 'src/components/Journey/MilestoneCard'
import {Header3} from 'src/components/text'

import {GuideSection} from './GuideSection'

const JourneySection = () => (
  <GuideSection title="Journey Elements">
    <Header3>Journey Start</Header3>
    <JourneyStart
      heroUrl="https://picsum.photos/900/300"
      title="Get to College"
      description="Mollit non et mollit aliquip. Amet amet ipsum ad ea ex esse amet dolore velit eu nulla."
    />

    <Header3>Milestone without hero</Header3>
    <MilestoneCard
      title="Scholarships: Where to start"
      articleCount={4}
      description="The 10 Scholarships that you should apply for first."
      link="/styleguide#journeyElements"
    />

    <Header3>Milestone with hero</Header3>
    <MilestoneCard
      heroUrl="https://picsum.photos/600/300"
      title="Your complete guide to the FASFA"
      articleCount={13}
      description="We walk your through applying for the FAFSA, start to finish."
      link="/styleguide#journeyElements"
    />

    <Header3>Article without hero</Header3>
    <Article
      title="Sunt minim veniam nulla consequat voluptate duis"
      description="Occaecat qui labore quis excepteur esse nisi non magna nostrud."
      link="/styleguide#journeyElements"
    />

    <Header3>Article with hero</Header3>
    <Article
      heroUrl="https://picsum.photos/600/300"
      title="Endless paperwork: How to get through it all"
      description="We walk your through applying fir the FAFSA, start to finish."
      link="/styleguide#journeyElements"
    />
  </GuideSection>
)

export default JourneySection
