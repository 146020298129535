import {useState} from 'react'

import Loading, {LoadingContentPlaceholder} from 'src/components/Loading'

import {GuideSection} from './GuideSection'
import {Lede} from '../text'
import {Box} from '../Box'

const LoadingSection = () => {
  const [showPlaceholder, setShowPlaceholder] = useState(false)
  const toggleShowPlaceholder = () => setShowPlaceholder((t) => !t)
  return (
    <GuideSection title="Loading">
      <Lede>Small</Lede>

      <Loading size="small" />

      <Lede>Medium</Lede>
      <Loading size="medium" />

      <Lede>Large</Lede>
      <Loading size="large" />

      <Box>
        <button onClick={toggleShowPlaceholder}>
          {showPlaceholder ? 'Hide ' : 'Show'} Loading Content Placeholder
        </button>
        {showPlaceholder && <LoadingContentPlaceholder size="large" />}
      </Box>
    </GuideSection>
  )
}

export default LoadingSection
