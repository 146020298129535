import {graphql} from 'gatsby'
import {ResourcePageCardFragment} from 'gatsby-graphql-types-generated'

import {Grid} from 'src/components/Box'
import {Header4, SmallBody} from 'src/components/text'
import Card from 'src/components/Card'
import ButtonLink from 'src/components/ButtonLink'

export const query = graphql`
  fragment ResourcePageCard on ContentfulResourcePage {
    __typename
    id
    title
    blurb {
      blurb
    }
    permalink
  }
`

interface Props {
  resourcePage: ResourcePageCardFragment
}

const ResourcePageCard = ({resourcePage}: Props) => (
  <Card>
    <Grid gridGap="3" css={{height: '100%'}}>
      <Header4 textAlign="center">{resourcePage.title}</Header4>
      {resourcePage.blurb?.blurb && (
        <SmallBody color="grey5" textAlign="center">
          {resourcePage.blurb.blurb}
        </SmallBody>
      )}
      <ButtonLink to={resourcePage.permalink}>Learn more</ButtonLink>
    </Grid>
  </Card>
)

export default ResourcePageCard
