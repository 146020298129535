import {Location} from '@reach/router'

import {Grid} from 'src/components/Box'
import Modal from 'src/components/Modal'
import ButtonLink from 'src/components/ButtonLink'
import {Lede} from 'src/components/text'
import {localPaths} from 'src/urls'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
  prompt: string
}

const AccountPromptModal = ({isOpen, onRequestClose, prompt}: Props) => (
  <Location>
    {({location}) => (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Grid p="4" gridGap="3" width="100%">
          <Lede textAlign="center">{prompt}</Lede>
          <ButtonLink
            to={localPaths.signUp}
            state={{returnTo: location.pathname}}
          >
            Sign up
          </ButtonLink>
          <ButtonLink
            variant="secondary"
            to="/log-in"
            state={{returnTo: location.pathname}}
          >
            Log in
          </ButtonLink>
        </Grid>
      </Modal>
    )}
  </Location>
)

export default AccountPromptModal
