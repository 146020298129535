import * as icons from 'src/components/icons'
import {Flex, Grid} from 'src/components/Box'
import {SmallBody} from 'src/components/text'

import {GuideSection} from './GuideSection'

const IconSection = () => (
  <GuideSection title="Icons">
    <Grid
      mt="3"
      gridGap="1em"
      gridTemplateColumns="repeat(auto-fill, minmax(6rem, 1fr))"
    >
      {Object.entries(icons).map(([name, Value]) => (
        <Flex
          key={name}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Value mb="1" />
          <SmallBody>{name}</SmallBody>
        </Flex>
      ))}
    </Grid>
  </GuideSection>
)

export default IconSection
