import React, {createContext, useContext, useEffect} from 'react'
import camelCase from 'lodash/camelCase'

import {GreenYellowUnderline} from 'src/components/emphasis'
import {Grid} from 'src/components/Box'
import {Header2} from 'src/components/text'
import * as styles from 'src/styles'

export const SectionContext = createContext<{
  registerId: (id: string, title: string) => void
  unregisterId: (id: string) => void
}>({
  registerId: () => {},
  unregisterId: () => {},
})

export const GuideSection = ({
  children,
  title,
}: {
  title: string
  children: React.ReactNode | React.ReactNode[]
}) => {
  const {registerId, unregisterId} = useContext(SectionContext)
  const id = camelCase(title)

  useEffect(() => {
    registerId(id, title)
    return () => unregisterId(id)
  }, [id, registerId, title, unregisterId])

  return (
    <Grid pt={styles.constants.DESKTOP_HEADER_HEIGHT} id={id} gridGap="3">
      <GreenYellowUnderline>
        <Header2>{title}</Header2>
      </GreenYellowUnderline>
      {children}
    </Grid>
  )
}
