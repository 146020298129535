import {
  Header1,
  Body,
  Lede,
  Header3,
  Header2,
  SmallBody,
} from 'src/components/text'
import {
  GreenYellowUnderline,
  BlackUnderline,
  BlueUnderline,
  HighlightUnderline,
} from 'src/components/emphasis'

import {GuideSection} from './GuideSection'

const TypographySection = () => (
  <>
    <GuideSection title="Typography">
      <Header1>
        header1 - Everything You Need to Know to Complete Your FAFSA
      </Header1>
      <Header2>header 2 - What is the FAFSA?</Header2>
      <Header3>header 3 - 2017 tax records, untaxed income, and assets</Header3>
      <Lede>
        lede - It is difficult for anyone who is new to FAFSA. Here's everything
        in FAFSA for them. Let's break down FAFSA.
      </Lede>
      inherited default (body) - The Free Application for Federal Student Aid
      (FAFSA) is a form that can be prepared annually by current and prospective
      college students (undergraduate and graduate) in the United States to
      determine their eligibility for student financial aid.
      <SmallBody>
        smallBody - It is difficult for anyone who is new to FAFSA. Here's
        everything in FAFSA for them. Let's break down FAFSA.
      </SmallBody>
      <Header3>Modifiers</Header3>
      <Lede>Truncate</Lede>
      <Body truncate>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris elit
        mauris, placerat sed ullamcorper imperdiet, facilisis sit amet mauris.
        Sed efficitur pulvinar eros, ut tincidunt ante congue in. Curabitur eget
        volutpat tortor, tristique rutrum diam. Proin in hendrerit ligula, at
        molestie orci. Nullam faucibus augue vel augue scelerisque, ac aliquet
        risus commodo. Integer scelerisque arcu vel vehicula gravida. Nullam vel
        felis a nisl venenatis vehicula vitae ut leo.
      </Body>
      <Lede>Hyphenate</Lede>
      <Body hyphenate>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris elit
        mauris, placerat sed ullamcorper imperdiet, facilisis sit amet mauris.
        Sed efficitur pulvinar eros, ut tincidunt ante congue in. Curabitur eget
        volutpat tortor, tristique rutrum diam. Proin in hendrerit ligula, at
        molestie orci. Nullam faucibus augue vel augue scelerisque, ac aliquet
        risus commodo. Integer scelerisque arcu vel vehicula gravida. Nullam vel
        felis a nisl venenatis vehicula vitae ut leo.
      </Body>
      <Lede>Break word</Lede>
      <Body breakWord>
        LoremipsumdolorsitametLoremipsumdolorsitametLoremipsumdolorsitametLoremipsumdolorsitametLoremipsumdolorsitamet,consecteturadipiscingelit.Mauriselitmauris,
      </Body>
      <Lede>Lines (line clamp)</Lede>
      <Body lines={2}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris elit
        mauris, placerat sed ullamcorper imperdiet, facilisis sit amet mauris.
        Sed efficitur pulvinar eros, ut tincidunt ante congue in. Curabitur eget
        volutpat tortor, tristique rutrum diam. Proin in hendrerit ligula, at
        molestie orci. Nullam faucibus augue vel augue scelerisque, ac aliquet
        risus commodo. Integer scelerisque arcu vel vehicula gravida. Nullam vel
        felis a nisl venenatis vehicula vitae ut leo.
      </Body>
    </GuideSection>

    <GuideSection title="Emphasis">
      <GreenYellowUnderline>
        <SmallBody>Green yellow underline for emphasis</SmallBody>
      </GreenYellowUnderline>
      <GreenYellowUnderline>
        <Header2>Green yellow underline for emphasis</Header2>
      </GreenYellowUnderline>

      <HighlightUnderline>
        <SmallBody>highlight underline for emphasis</SmallBody>
      </HighlightUnderline>

      <BlackUnderline>
        <SmallBody>black underline for emphasis</SmallBody>
      </BlackUnderline>
      <BlackUnderline>
        <Header2>black underline for emphasis</Header2>
      </BlackUnderline>

      <BlueUnderline>
        <SmallBody>black underline for emphasis</SmallBody>
      </BlueUnderline>

      <Header2>
        <BlueUnderline>blue underline for emphasis</BlueUnderline>
      </Header2>
    </GuideSection>
  </>
)

export default TypographySection
