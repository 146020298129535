import {ReactNode, useState, useRef, useEffect, MutableRefObject} from 'react'
import {Form, FormRenderProps} from 'react-final-form'
import {FormApi, Config, AnyObject} from 'final-form'
import {useTimeoutFn} from 'react-use'

interface InlineFieldRenderProps<FormValues>
  extends FormRenderProps<FormValues> {
  editable: boolean
  setEditable: (isEditable: boolean) => void
  inputRef: MutableRefObject<HTMLInputElement | null>
  submit: () => void
}

interface Props<FormValues> extends Config<FormValues> {
  children: (renderProps: InlineFieldRenderProps<FormValues>) => ReactNode
}

const InlineField = <FormValues extends AnyObject>({
  children,
  ...formProps
}: Props<FormValues>) => {
  const formApi = useRef<FormApi<FormValues> | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [editable, setEditable] = useState<boolean>(false)
  const [, cancel, reset] = useTimeoutFn(() => {
    setEditable(false)
  }, 100)

  useEffect(() => {
    if (inputRef.current && editable) {
      inputRef.current.focus()
    }

    if (!editable && formApi.current) {
      formApi.current.reset()
    }
  }, [editable])

  const submit = () => {
    if (!formRef.current) {
      return
    }

    formRef.current.dispatchEvent(
      new Event('submit', {
        bubbles: true,
        cancelable: true,
      })
    )
  }

  return (
    <Form {...formProps}>
      {(formRenderProps) => {
        formApi.current = formRenderProps.form
        return (
          <form
            ref={formRef}
            onFocus={cancel}
            onBlur={reset}
            onSubmit={async (event) => {
              cancel()
              const clientFormErrors = Object.values(
                formRenderProps.errors || {}
              ).join(' ')

              if (clientFormErrors) {
                event.preventDefault()
                inputRef.current && inputRef.current.focus()
                return clientFormErrors
              }

              const error = await formRenderProps.handleSubmit(event)
              if (error) {
                return error
              }
              setEditable(false)
            }}
          >
            {children({
              ...formRenderProps,
              editable,
              inputRef,
              setEditable,
              submit,
            })}
          </form>
        )
      }}
    </Form>
  )
}

export default InlineField
