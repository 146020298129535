import {useTheme} from '@emotion/react'

import {Box, Flex, PositionContainer} from 'src/components/Box'

import {GuideSection} from './GuideSection'

const BreakpointSection = () => {
  const theme = useTheme()

  return (
    <GuideSection title="Breakpoints">
      <Box height="14rem">
        <PositionContainer position="absolute" left="0" right="0">
          <Flex my="3" width="100%" flexDirection="column">
            <Box width="100%" background="lightgrey">
              <Box p="2">default</Box>
            </Box>
            <Box
              display={{
                default: 'none',
                sm: 'block',
              }}
              width={theme.breakpoints.sm}
              background="yellow"
            >
              <Box p="2">small - {theme.breakpoints.sm}</Box>
            </Box>
            <Box
              display={{
                default: 'none',
                md: 'block',
              }}
              width={theme.breakpoints.md}
              background="lightblue"
            >
              <Box p="2">medium - {theme.breakpoints.md}</Box>
            </Box>
            <Box
              display={{
                default: 'none',
                lg: 'block',
              }}
              width={theme.breakpoints.lg}
              background="lightgreen"
            >
              <Box p="2">large - {theme.breakpoints.lg}</Box>
            </Box>
          </Flex>
        </PositionContainer>
      </Box>
    </GuideSection>
  )
}

export default BreakpointSection
