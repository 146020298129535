import {OrderedList, UnorderedList} from 'src/components/List'
import {Lede, Header3} from 'src/components/text'

import {GuideSection} from './GuideSection'

const ListSection = () => (
  <GuideSection title="Lists">
    <Header3>Ordered Lists</Header3>

    <OrderedList flowDirection="row">
      <Lede>Do thing</Lede>
      <Lede>Do Other thing</Lede>
      <Lede>Do thing</Lede>
      <Lede>Do Other thing</Lede>
    </OrderedList>

    <Lede>As columns</Lede>
    <OrderedList flowDirection="column">
      <Lede>Do thing</Lede>
      <Lede>Do Other thing</Lede>
      <Lede>Do thing</Lede>
      <Lede>Do Other thing</Lede>
    </OrderedList>

    <Header3>Unordered Lists</Header3>
    <UnorderedList flowDirection="row">
      <Lede>Do thing</Lede>
      <Lede>Do Other thing</Lede>
      <Lede>Do thing</Lede>
      <Lede>Do Other thing</Lede>
    </UnorderedList>

    <Lede>As columns</Lede>
    <UnorderedList flowDirection="column">
      <Lede>Do thing</Lede>
      <Lede>Do Other thing</Lede>
      <Lede>Do thing</Lede>
      <Lede>Do Other thing</Lede>
    </UnorderedList>
  </GuideSection>
)

export default ListSection
