import {GatsbyImageSharpFluid_WithWebpFragment} from 'gatsby-graphql-types-generated'
import Img from 'gatsby-image'
import {ClassNames} from '@emotion/react'

import {Flex} from 'src/components/Box'
import {Maybe} from 'src/graphql-generated'

interface Props {
  alt: string
  fluid?: Maybe<GatsbyImageSharpFluid_WithWebpFragment>
  src: string
  maxHeight?: number | string
  cover?: boolean
}

const Image = ({alt, src, maxHeight, cover = false, fluid}: Props) => {
  if (fluid) {
    return (
      <ClassNames>
        {({css}) => (
          <Img
            className={css({maxHeight})}
            imgStyle={{
              objectFit: cover ? 'cover' : 'contain',
            }}
            fluid={fluid as any}
            alt={alt}
          />
        )}
      </ClassNames>
    )
  }

  return (
    <Flex justifyContent="center">
      <img
        css={[
          {
            maxHeight,
          },
          cover
            ? {
                objectFit: 'cover',
                width: '100%',
              }
            : {
                objectFit: 'contain',
                maxWidth: '100%',
              },
        ]}
        src={src}
        alt={alt}
      />
    </Flex>
  )
}

export default Image
