import {useState} from 'react'
import {Form} from 'react-final-form'

import Button from 'src/components/Button'
import {Box, Flex, Grid} from 'src/components/Box'
import TextField from 'src/components/form/TextField'
import {required} from 'src/form/validation'
import ToggleField from 'src/components/form/ToggleField'
import RadioInput from 'src/components/RadioInput'
import CheckboxField from 'src/components/form/CheckboxField'
import CheckboxInput from 'src/components/CheckboxInput'
import DateField from 'src/components/form/DateField'
import InlineTextField from 'src/components/form/InlineTextField'
import SearchInput from 'src/components/form/SearchInput'
import TextAreaField from 'src/components/form/TextAreaField'
import RoundedTextField from 'src/components/form/RoundedTextField'

import {GuideSection} from './GuideSection'
import StateContainer from './StateContainer'

const StyleguideForm = ({
  children,
  initialValues,
}: {
  children?: React.ReactNode | React.ReactNode[]
  initialValues?: any
}) => {
  const [state, setState] = useState<any>({})

  return (
    <Flex flexDirection="column">
      <Form initialValues={initialValues} onSubmit={setState}>
        {({handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            {children}
            <Box mt="3">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Box>
          </form>
        )}
      </Form>
      <pre
        css={{
          fontFamily: 'monospace',
          whiteSpace: 'pre',
          backgroundColor: 'lightGrey',
          padding: '1rem',
          borderRadius: '10px',
        }}
      >
        {JSON.stringify(state, null, 2)}
      </pre>
    </Flex>
  )
}

const FormSection = () => (
  <>
    <GuideSection title="Inputs">
      <SearchInput name="search-input" placeholder="Do some searching.." />

      <CheckboxInput />
      <RadioInput name="radio-input" value="A" label="A" />
      <RadioInput name="radio-input" value="B" label="B" />
    </GuideSection>

    <GuideSection title="Form Fields">
      <StyleguideForm
        initialValues={{
          textField: 'TextField',
          readOnlytextField: 'ReadOnly',
          roundedTextField: 'RoundedTextField',
          birthdate: {year: '2019', month: '9', day: '25'},
          disabledBirthdate: {year: '2019', month: '9', day: '25'},
        }}
      >
        <TextField label="Label" name="emptyTextField" />

        <TextField
          validate={required}
          label="Required Label"
          name="textField"
        />

        <TextField readOnly label="Label" name="readOnlytextField" />

        <RoundedTextField
          validate={required}
          label="Label"
          name="roundedTextField"
        />

        <TextAreaField label="Text area" name="textArea" />

        <Grid gridGap="3" maxWidth="300px" mt="3">
          <ToggleField label="Toggle" name="exampleToggle" />
          <ToggleField
            label="Disabled Toggle"
            name="disableExampleToggle"
            disabled
          />
        </Grid>

        <Box mt="3">
          <CheckboxField name="checkbox" />
          <CheckboxField name="disabledCheckbox" disabled />
        </Box>

        <Box mt="3">
          <DateField name="someday" />
          <DateField name="birthdate" />
          <DateField name="disabledBirthdate" disabled />
        </Box>
      </StyleguideForm>
    </GuideSection>

    <GuideSection title="Inline Fields">
      <StateContainer initialValue={''}>
        {({state, setState}) => (
          <InlineTextField
            label="Inline Edit"
            name="inlineTextField"
            initialValue={state}
            onSubmit={(value) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  setState(value)
                  resolve()
                }, 2000)
              })
            }
          >
            {({editable, submitting, ...fieldProps}) => (
              <TextField
                validate={required}
                readOnly={!editable || submitting}
                {...fieldProps}
              />
            )}
          </InlineTextField>
        )}
      </StateContainer>
    </GuideSection>
  </>
)

export default FormSection
